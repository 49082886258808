/**
 * Theme Variables
 */

// SVG Base64 Curves
$industry-bottom-curve: "data:image/svg+xml,%3Csvg width='1920' height='118' viewBox='0 0 1920 118' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_749_3095)'%3E%3Cpath d='M1384 -1001.23C1584.7 -1079.38 1844 -1045.23 1940 -975.227L1969 64.8569C1871.82 25.722 1752 14.7653 1602.59 50.589C1293 124.819 960.52 114.725 713 69.1464C229 -19.9783 102.462 22.1664 -49 39.3785L-37.5028 -920.99C176.444 -994.64 443 -956.227 655.5 -920.99C881.483 -883.517 1168 -917.117 1384 -1001.23Z' fill='%23EDF7FC'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_749_3095'%3E%3Crect width='1920' height='118' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A" !global;


// Misc
:root {
    --law-dropshadow: 6px 4px 18px rgba(0, 0, 0, 0.2);
    --law-radius: 40px;
}

// Fonts

$opensans: 'Open Sans', sans-serif; // headers
$OpenSans: $opensans;
$roboto: 'Roboto', sans-serif; // body
$Roboto: 'Roboto', sans-serif; // body

:root {
    --opensans-font: #{$opensans};
    --roboto-font: #{$roboto};
}

$mont: $roboto;
$fa: Font Awesome\ 5 Pro !global;
$far: Font Awesome\ 5 Regular !global;
$fas: Font Awesome\ 5 Solid !global;
$fal: Font Awesome\ 5 Light !global;
$fab: Font Awesome\ 5 Brands!global;


// Colours

$law-blue: #4AABE6 !global;
$law-blue-a11y: #0082d0 !global;
$law-grayblue: #415C6C !global;

$law-lightblue: #EDF7FC !global;

$law-yellow: #FFB64F !global;
$law-orange: #F18A00 !global;
$law-lightgray: #6C6E6C !global;
$law-gray: #3F4E65 !global;
$law-darkgray: #393939 !global;
$true-black: #000000;
$black: #333;
$true-white: #ffffff;

:root {
    --law-lightblue: #{$law-lightblue};
    --law-blue: #{$law-blue};
    --law-grayblue: #{$law-grayblue};
    --law-darkblue: #{$law-grayblue};
    --law-blue-a11y: #{$law-blue-a11y};
    --a11y-blue-outline: #0847C0;

    --law-yellow: #{$law-yellow};

    --law-orange: #{$law-orange};
    --law-lightgray: #{$law-lightgray};
    --law-gray: #{$law-gray};
    --law-darkgray: #{$law-darkgray};
}


// Font Weight

$weight-extralight: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-black: 700;

$global-weight-light: 300;
$global-weight-regular: 400;
$global-weight-medium: 500;
$global-weight-semibold: 600;
$global-weight-bold: 700;


// Misc

$grid-gutter: 15px;






