/**
 * Flexible Testimonials Section
 */
 
@import "assets/scss/utilities/mixins";
@import "assets/scss/utilities/variables";
@import "../../../node_modules/foundation-sites/scss/util/_unit.scss";
@import "../../../node_modules/foundation-sites/scss/util/_breakpoint.scss";

// styles moved to _global.scss
